.site-header {
  // background: #2a373d url($header-background-image) no-repeat center center/cover;
  color: #fff;
  text-align: center;
  position: relative;
  width: 100%;
  display: table;
  transition: all 0.3s ease;

  .hero-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    filter: brightness(40%);
  }

  .transparent-layer {
    display: table-cell;
    vertical-align: middle;
    height: 180px;
    width: 100%;
    // background-color: rgba(34, 34, 34, 0.6);
    @include breakpoint("small") {
      height: 400px;
    }
  }

  h1 {
    margin: 0;
    font-size: 36px;
    background-size: 90px 74px;
    vertical-align: middle;
    font-weight: 500;
    @include breakpoint("small") {
      font-size: 56px;
    }
  }

  h1 .logo {
    margin: 0;
    width: auto;
    height: 148px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;

    background: url($header-background-image) no-repeat 0 0;
    @media (min--moz-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 2.6 / 2),
      (-webkit-min-device-pixel-ratio: 1.3),
      (min-device-pixel-ratio: 1.3),
      (min-resolution: 1.3dppx) {
      background-image: url($header-background-image);
    }
  }

  .nav-drop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    padding: 12px;
  }
}

nav {
  ul {
    padding: 0;
  }
  ul,
  li {
    display: inline;
  }

  a {
    display: inline-block;
    color: white;
    margin: 0 8px;
    font-family: "Roboto", sans-serif;
    transition: all 0.3s ease;

    &:hover {
      color: $accent-color;
    }
  }

  .active a {
    color: $accent-color;
    border-bottom: solid 2px white;
  }
}

body.post-template .site-header h1 .logo {
  width: 90px;
  height: 74px;
  margin: -45px 0 0 -37px;
  background-size: 90px 74px;
}
