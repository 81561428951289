@charset "UTF-8";

@import "variables";
@import "mixins";

// page components
@import "partials/reset";
@import "partials/base";
@import "partials/header";
@import "partials/content";
@import "partials/author";
@import "partials/social";
@import "partials/crypto";
@import "partials/forms";
@import "partials/footer";
@import "partials/all";

// fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,700|Noto+Serif:400,400italic,700,700italic");
@import "vendor/font-awesome";

// vendor libraries
@import "vendor/highlightjs/monokai";

