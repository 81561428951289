.field {
  margin-bottom: 2em;
  &.action {
    margin-top: 3em;
  }
}

.field label {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.6;
}

.field button,
.field input,
.field optgroup,
.field select,
.field textarea {
  font-family: "Noto Serif", serif;
  display: block;
  font-weight: 300;
  line-height: 1.6;
}

.field input,
.field select,
.field textarea {
  width: 100%;
  padding: 10px;
}

.field input[type="button"],
.field input[type="submit"] {
    width: 100px;
    font-family: "Roboto", sans-serif;
    padding: 0;
}

.field textarea {
  height: 10em;
}

.btn {
  border: solid 1px #ececec;
  background-color: #fff;
  width: 100px;
  height: 35px;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 35px;
  font-size: 13px;
  color: #000;
}
.btn:hover {
  background-color: #2a373d;
  border-color: #2a373d;
  color: #fff;
}

.btn.btn-download {
  background-color: #2a373d;
  border-color: #2a373d;
  color: #fff;
  width: 200px;
}
.btn.btn-download:hover {
  background-color: #49606a;
  border-color: #2a373d;
  color: #fff;
}

.btn.home {
  width: 140px;
}

input[type="submit"],
input[type="button"] {
  transition: all 300ms;
}

input[type="submit"].primary,
input[type="button"].primary {
  background-color: $alternative-light-color;
}

input[type="submit"].primary:hover,
input[type="button"].primary:hover {
  @extend .btn:hover;
}
