.content {
  width: 60%;
  margin-right: 5%;
  float: right;
}

@media only screen and (max-width: 719px) {
  .content {
    width: 100%;
    margin: 0;
  }
}

.post ul li {
  line-height: 28px;
  font-size: 15px;
}

.post h2 {
  margin-top: 0;
}

.post p {
  line-height: 28px;
}

.post h2 a,
.post-template h1 {
  font-size: 44px;
  margin-bottom: 10px;
}

.post-template h2,
.post-template h3,
.post-template h4,
.post-template h5,
.post-template h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.post {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #ececec;
}

.post img {
  max-width: 100%;
  height: auto;
}

.post .note {
  font-size: 80%;
  color: $secondary-color;
  padding: 0.25em 0;
  margin: 1.25em 0;
  border-top: dashed 1px lighten($secondary-color, 20%);
  border-bottom: dashed 1px lighten($secondary-color, 20%);
}

.post-info {
  color: #a2a2a2;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 40px;
  line-height: 30px;
}
