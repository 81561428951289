.author {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-align: center;
  width: 25%;
  float: left;
  margin: 20px 5% 0px 5%;
}

.author p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.author p.name {
  font-size: 14px;
  margin: 10px 0;
}

.author p.address {
  color: #979797;
}

.author p.link {
  margin-bottom: 10px;
}

.profile-image {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

@media only screen and (max-width: 719px) {
  .author {
    width: 100%;
    margin: 0;
  }

  .author {
    margin-bottom: 60px;
  }
}
