.main-footer {
    background-color: $alternative-light-color;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: $secondary-color;
  }
  
  .main-footer .container {
    padding: 30px 0;
  }
  
  .main-footer a {
    color: $secondary-color;
  }
  .main-footer a:hover {
    color: $primary-color;
  }
  
  .main-footer p {
    font-size: 11px;
  }
  