ul.crypto-tip {
  list-style: none;
  padding: 1em 0 0;
}

// ul.crypto-tip li {
//   display: inline;
// }

ul.crypto-tip li {
  line-height: 1.4em;
  font-size: 12px;
}
