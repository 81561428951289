ul.social {
  list-style: none;
  display: inline;
  padding: 0;
}

ul.social li {
  display: inline;
}

ul.social li a {
  padding: 5px;
  font-size: 20px;
}

.share-buttons {
  list-style: none;
  padding: 0;
  margin-top: 60px;
}

.share-buttons li {
  display: inline;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: #979797;
  margin-right: 4px;
}

.share-buttons li a {
  font-size: 15px;
}

.comments {
  margin-bottom: 60px;
}

