/* -----------------------------------------------------------------------------
   -----------------------------------------------------------------------------

   Theme: Minimalist Theme
   Version: 1.0
   Author: Raphael Riegger (http://rriegger.com)
   License: Apache License Version 2.0 (http://www.apache.org/licenses/LICENSE-2.0)

   -----------------------------------------------------------------------------
   -----------------------------------------------------------------------------*/
body {
  color: $primary-color;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  transition: all 0.3s ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;

  a {
    color: $primary-color;

    &:hover {
      color: #a2a2a2;
    }
  }
}

a {
  text-decoration: none;
  color: #a2a2a2;
  transition: all 0.3s ease;
  &:hover {
    color: $primary-color;
  }
}

hr {
  border: 0;
  border-top: 1px #e4e4e4 solid;
  margin: 40px 0;
}

.container {
  margin: 0 auto;
  width: 1200px;
  padding: 50px 0 0 0;
}

@media only screen and (min-width: 720px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 719px) {
  .container {
    width: 90%;
    padding-top: 25px;
  }
}

.tags {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.tag-title {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-bottom: 1px solid #ececec;
  margin-bottom: 40px;
}

.tag-title h1 {
  font-size: 28px;
  margin-top: 0;
  margin-bottom: 40px;
}

.tag-title p {
  color: #979797;
  font-size: 14px;
  margin-bottom: 0;
}

.pagination {
  position: relative;
  height: 35px;
  width: 100%;
  margin-bottom: 60px;
}

.newer {
  position: absolute;
  left: 0;
  top: 0;
}

.older {
  position: absolute;
  right: 0;
  top: 0;
}

.counter {
  text-align: center;
  line-height: 35px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #979797;
}


.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

blockquote {
  border-left: 5px solid $secondary-color;
  color: $secondary-color;

  padding: 0.1em 2em;
  margin: 0 0 2em;
}

dl {
  // margin-left:1em;
  // margin-right: 1em;
}

dt {
  font-weight: 700;
  font-size: 90%;
  margin-bottom: 0.2em;}

dd {
  font-size: 80%;
  margin-left: 0;
  margin-bottom: 1em;
  font-family: "Roboto", sans-serif;}