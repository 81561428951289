$primary-color: #222222;
$secondary-color: #a2a2a2;
$alternative-light-color: #f3f3f3;
$accent-color: orange;

// $header-background-image: "/img/desk.jpg";
$header-background-image: "/img/header-bg.jpg";

$breakpoints: (
  'small':  720px,
  'medium': 992px,
  'large':  1200px
) !default;